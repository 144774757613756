import * as React from "react"

const Contact = ({title, blurb}) => {
  
  return (
  <div className='max-550 ma large'>
    <div className='mb40'>
      <p className='m0 h3 m-m0'>{title}</p>
      <p className='m0 mt20 max-450'>{blurb}</p>
    </div>
    <div className='mb40 white-space hide'>
      <p className='m0 mb10'>Our Location </p>
      {"PO BOX 387, Newfarm, QLD, 4005"}
    </div>
    <div className='mb40'>
      <p className='m0 mb10'>Phone</p>
      <a className='link ul-link white' href={'tel:'}>{"0401 187 100"}</a>
    </div>
    <div className='mb40'>
      <p className='m0 mb10'>Email</p>
      <a className='link ul-link white' href={'mailto:'}>{"Info@pulsi.com.au"}</a>
    </div>
  </div>
  )
}

export default Contact
