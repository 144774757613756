import * as React from "react"
import Layout from "../components/layout"
import Contact from "../components/contact"
import Spacer from "../components/spacer"
import Form from "../components/form"
import Seo from "../components/seo"

const SignUpPage = () => {
  return (
  <Layout>
      <Seo title="Contact Us" />
      <div className='p20 max-1600 ma'>
        <div className='bg-blue white br-60 p40'>
          <Spacer className='m-small' />
          <div className='flex m-wrap'>
            <div className='w-50 m-100'>
              <Contact title='Sign Up today' blurb='Step up your game with Pulsi. Register now for early access.' />
            </div>
            <div className='w-50 m-100'>
              <Form />
            </div>
          </div>
          <Spacer className='m-small' />
        </div>
      </div>
  </Layout>
  )
}

export default SignUpPage

